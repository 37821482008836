import * as React from 'react';

import type { BasePageProps } from '~/types/base-page';

export type AppContext = Omit<BasePageProps, 'session'> & {
  hideIntro: boolean;
  setHideIntro: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppContext = React.createContext<AppContext | undefined>(undefined);

const AppContextProvider: React.FC<{ value: AppContext }> = ({
  children,
  value,
}) => <AppContext.Provider value={value}>{children}</AppContext.Provider>;

function useApp() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be wrapped in a AppContextProvider');
  }
  return context;
}

export { AppContextProvider, useApp };
