import ProgressBar from '@badrap/bar-of-progress';
import { getUtilityTheme } from '@pwly/powerley-utils';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';
import * as React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'node_modules/react-modal-video/css/modal-video.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { AppContextProvider } from '~/components/context';
import '~/styles/global.css';
import '~/styles/graphik.css';
import '~/styles/shepherd.css';
import '~/styles/simulator-modal.css';
import '~/styles/simulator.css';
import type { BasePageProps } from '~/types/base-page';

const progress = new ProgressBar({
  size: 4,
  color: '#00a979',
  className: 'bar-of-progress',
  delay: 100,
});

// this fixes safari jumping to the bottom of the page
// when closing the search modal using the `esc` key
if (typeof window !== 'undefined') {
  progress.start();
  progress.finish();
}

const pageView = (url: string) => {
  try {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
  } catch (e: unknown) {
    console.warn('pageView', e);
  }
};

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', (url: string) => {
  progress.finish();
  window.scrollTo(0, 0);
  window.onload = () => {
    pageView(url);
  };
});

Router.events.on('routeChangeError', progress.finish);

const DTEHeader = dynamic(async () => {
  const m = await import('~/components/dte-nav');
  return m.DTEHeader;
});

const DTENavigation = dynamic(async () => {
  const m = await import('~/components/dte-nav');
  return m.DTENavigation;
});

const DTEFooter = dynamic(async () => {
  const m = await import('~/components/dte-nav');
  return m.DTEFooter;
});

interface AppPropsWithError extends AppProps<BasePageProps & { err?: Error }> {
  err?: Error;
  pageProps: BasePageProps & { hideIntro: boolean };
}

declare global {
  interface Window {
    dataLayer: {
      push(event: { event: string; page: string }): void;
    };
    cxone: any;
  }
}

export function reportWebVitals(metric) {
  if (process.env.ENV !== 'production') {
    console.info(metric);
  }
}

const App = ({ Component, pageProps, err }: AppPropsWithError) => {
  const utility = pageProps.utility;
  const [navOpen, setNavOpen] = React.useState(false);
  const showNavigation =
    ['pwly', 'dtei'].includes(utility) && !pageProps.isWebView;
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 10000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
          },
        },
      })
  );
  // const showNavigation = true;

  // console.log('pageProps.isWebView', pageProps.isWebView);
  // console.log(['pwly', 'dtei'].includes(utility), utility);

  const theme = React.useMemo(() => getUtilityTheme(utility), [utility]);

  const globalStyles = React.useMemo(
    () =>
      Object.entries(theme)
        .map(([key, val]) => `--${key}: ${val};`)
        .join('\n'),
    [theme]
  );

  React.useEffect(function removeServiceWorker() {
    window.addEventListener('load', async () => {
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker
          .getRegistrations()
          .then(function unRegisterWroker(registrations) {
            for (const registration of registrations) {
              registration.unregister().catch(error => {
                // Failed to unregister
                console.error(`Un-registration failed with ${error}`);
              });
            }
          });
      }
    });
  }, []);

  const [hideIntro, setHideIntro] = React.useState(() => {
    let introSession;
    try {
      introSession = localStorage.getItem('bill-impact-intro');
    } catch (e: unknown) {
      // Do nothing;
    }
    if (introSession === 'done') {
      return true;
    } else {
      return false;
    }
  });

  const router = useRouter();
  const isSimulator = router.pathname === '/bill-simulator';

  const name = pageProps.session?.user.name as string;

  return (
    <SessionProvider session={(pageProps as any).session}>
      <AppContextProvider
        value={{
          utility,
          serviceNetworkId: pageProps.serviceNetworkId,
          customerId: pageProps.customerId,
          customerSiteId: pageProps.customerSiteId,
          isWebView: pageProps.isWebView,
          customerSites: pageProps.customerSites,
          timeZone: pageProps.timeZone,
          accountNumber: pageProps.accountNumber,
          fuelType: pageProps.fuelType,
          nextEst: pageProps.nextEst,
          peakUsages: pageProps.peakUsages,
          eligibilityData: pageProps.eligibilityData,
          name,
          hideIntro,
          setHideIntro,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Head>
            {process.env.ENV !== 'production' ? (
              <meta name="robots" content="noindex,nofollow" />
            ) : null}
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, viewport-fit=cover"
            />
            <link
              rel="manifest"
              href="/manifest.webmanifest"
              crossOrigin="use-credentials"
            />
            <link rel="icon" sizes="192x192" href="/favicon-192x192.png" />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.jpg"
            />
            {/* Smart App Banner */}
            {/* <meta name="apple-itunes-app" content="app-id=APP_ID,affiliate-data=AFFILIATE_ID,app-argument=SOME_TEXT" /> */}
            {/* Launch Screen Image */}
            <link rel="apple-touch-startup-image" href="/favicon.png" />
            {/* Launch Icon Title */}
            {/* <meta name="apple-mobile-web-app-title" content="App Title" /> */}
            {/* Enable standalone (full-screen) mode */}
            <meta name="apple-mobile-web-app-capable" content="yes" />
            {/* Status bar appearance (has no effect unless standalone mode is enabled) */}
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="black-translucent"
            />
            {/* iOS app deep linking */}
            {/* <meta name="apple-itunes-app" content="app-id=APP-ID, app-argument=http/url-sample.com" /> */}
            {/* <link rel="alternate" href="ios-app://APP-ID/http/url-sample.com" /> */}
            <meta name="theme-color" content={theme.primary} />
            {/* Add to home screen */}
            <meta name="mobile-web-app-capable" content="yes" />
            {/* Android app deep linking */}
            {/* <meta name="google-play-app" content="app-id=package-name" /> */}
            {/* <link rel="alternate" href="android-app://package-name/http/url-sample.com" /> */}
            <style
              dangerouslySetInnerHTML={{
                __html: `
                :root {
                  ${globalStyles}
                }
              `,
              }}
            />
          </Head>

          {/* <SimulatorContextWrapper value={{ nextBill: 10, yearBill: 100 }}> */}
          {/* {showAppShell ? ( */}
          <div className="flex min-h-screen flex-col">
            {showNavigation ? <DTEHeader setNavOpen={setNavOpen} /> : null}
            {/* Hack to allow React Router to run in a Next.js app. */}
            {isSimulator ? (
              <div suppressHydrationWarning>
                {typeof window === 'undefined' ? null : (
                  <Component {...pageProps} err={err} />
                )}
              </div>
            ) : (
              <Component {...pageProps} err={err} />
            )}
            {showNavigation ? (
              <>
                <DTENavigation setNavOpen={setNavOpen} navOpen={navOpen} />
                <DTEFooter />
              </>
            ) : null}
          </div>
          {/* ) : (
              <Component {...pageProps} err={err} />
            )} */}
          {/* </SimulatorContextWrapper> */}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppContextProvider>
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
          (function(n,u){window.CXoneDfo=n,window[n]=window[n]||function(){(window[n].q=window[n].q||[]).push(arguments)},window[n].u=u,e=document.createElement("script"),e.type="module",e.src=u+"?"+Math.round(Date.now()/1e3/3600),document.head.appendChild(e)})('cxone','https://web-modules-de-na1.niceincontact.com/loader/1/loader.js');

          cxone('init', '4627');
          cxone('guide','init');

          cxone('chat','hideQueueCounterAfterAssignment');
          cxone('chat','showTermsOfUse', 'Show terms of Use', 'externalLink', 'https://www.dteenergy.com/us/en/quicklinks/terms-conditions.html');
          cxone('chat','setCaseCustomField', 'url', window.location.href);
          cxone('chat','setCustomCss', '[data-selector="HEADER"] {color: white !important; background: #0C2340 !important}');
          cxone('chat','setCustomCss', '[data-selector="ACTIVITY_BAR"] {display: none !important}');

          console.log('nice chat loaded', window.cxone);
          `
        }}
      />
    </SessionProvider>
  );
};

export default App;
